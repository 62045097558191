import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiBriefcase, FiTrendingUp, FiThumbsUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";


const ServiceList = [
    {
        icon: <FiBriefcase />,
        title: 'Contract Negotiation',
        description: 'The smallest contractual details can affect the trajectory of a player\'s career. Our attention to detail and expertise allows us to negotiate player-friendly contracts for all of our clients, giving them the flexibility and peace of mind they need to perform their best on the field.'
    },
    {
        icon: <FiTrendingUp />,
        title: 'Investments/Post-Career Guidance',
        description: 'Money management is the most important aspect of an athlete\'s career. Whether it be investment guidance, acquiring the proper insurance, or assisting the player and his family in post-career decision-making, our clients can take extreme comfort in the fact that we are here to help in all of the important decisions.'
    },
    {
        icon: <FiThumbsUp />,
        title: 'Marketing/Endorsements',
        description: 'Led by our vastly experienced marketing director John McCluskey, our team is constantly looking to maximize our client\'s off the field earnings and give them the best exposure possible in order to elevate their personal brand.'
    }
    
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Services' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Services'}   />
                {/* End Breadcrump Area */}

                {/* Start Service Area */}
                <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Our Services</h2>
                                    <p>Our mission is to provide exceptional service to all of our clients that will allow them to put their entire concentration onto the field of play. We are very selective in the number of players that we work with in order to give those that we do work with our full undivided attention and the best possible representation in all areas. In addition to being relentless workers, the entire MWD Soccer staff is fully equipped with a plethora of experience in order to provide top-notch management services for all clients.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

            

                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Service;