import React, { Component , Fragment } from "react";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/FooterTwo";
import Helmet from "../component/common/Helmet";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const PortfolioList = [
    {
        image: 'image-1'
    },
    {
        image: 'image-2'
    },
    {
        image: 'image-3'
    },
    {
        image: 'image-4'
    },
    {
        image: 'image-5'
    },
    {
        image: 'image-6'
    },
    {
        image: 'image-7'
    },
    {
        image: 'image-8'
    }
]

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'MWD Soccer',
        description: '​A full service sports management agency in the New York metropolitan area.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]
class HomePortfolio extends Component{
    render(){
        return(
            <Fragment> 
                <Helmet pageTitle="Home Portfolio" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--36" key={index} data-black-overlay="7">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description" style={{fontSize: "28px"}}>{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
                {/* End Slider Area   */}

                
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2>MWD</h2>
                                    <p style={{fontStyle: "italic"}}>Specializing in the domestic and international transfers of professional soccer players.</p>
                                    <p style={{fontStyle: "italic"}}>Currently representing players across North America, Europe, and Africa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--70 mt_sm--40">
                            <Slider {...portfolioSlick2} autoplay={true} autoplaySpeed={1500}>
                                {PortfolioList.map((value , index) => (
                                    <div className="portfolio" key={index}>
                                        <a href="/clients">
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}



                {/* Start portfolio Area  */}
                {/* <div className="rn-portfolio-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                     <h2 className="title">Our Project</h2>
                                    <p>Specializing in the domestic and international transfers of professional players.</p>
                                    <p>Currently representing players across North America, Europe, and Africa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <PortfolioMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" />
                        </div>
                       
                    </div>
                </div> */}
                {/* End portfolio Area  */}

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">Our Clients</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <Brand branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Footer Area  */}
                <FooterTwo />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default HomePortfolio;