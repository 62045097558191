import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 1,
                dollar: '$',
                add: 'M',
                plus: '+',
                countTitle: 'Total contractual value + transfer fees negotiated',
            },
            {
                countNum : 700,
                add: '',
                plus: '+',
                countTitle: '# of clubs in our network on TransferRoom',
            },
            {
                countNum : 5,
                add: '',
                plus: '',
                countTitle: '# of MLS SuperDraft eligible players represented since 2020',
            },
            {
                countNum : 24,
                add: '',
                plus: '',
                countTitle: 'players represented across 8 leagues',
            },
            {
                countNum : 15,
                add: '',
                plus: '+',
                countTitle: 'contract extensions and transfers negotiated among our clients and partners',
            }
           
        ];

        return(
            <Fragment>
                <div className="row">
                    {Data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                {value.dollar}
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                                {value.add}{value.plus}
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;