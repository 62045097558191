

let data2 = [
    {
        images: '01',
        title: 'Mike Dietze ',
        job: 'Owner',
        designation: 'Mike graduated from Seton Hall University with a Bachelor of Science in Sport Management and a M.B.A. in Marketing. After playing professionally for the Fort Lauderdale Strikers and Philadelphia Fury, he decided to combine his high-level playing experience and sharp business acumen to forge MWD Soccer Agency. As a licensed FIFA Agent and founder, Mike oversees all client relationships, handles all contract negotiations, and fosters all new partnerships, both domestically and abroad.'
        
    },
    {
        images: '02',
        title: 'Bryan Longo',
        job: 'Head of Scouting',
        designation: 'Spearheading MWD Soccer\'s scouting and recruiting department, Bryan has extensive experience finding hidden talent at all levels of the soccer pyramid. Previously the first assistant coach for MIT Men\'s Soccer, Bryan completely turned the program around. Through exceptional player evaluation and recruitment, he assembled a roster that achieved a #6 national ranking. On a day to day basis, Bryan manages a wide-ranging scouting network and handles all player analysis platforms such as TransferRoom, Wyscout, and Hudl.',
       
    },
    {
        images: '03',
        title: 'John McCluskey',
        job: 'Marketing Director',
        designation: 'With over twenty years of global and domestic sports marketing experience, John leads the commercial sector of MWD Soccer. As the former senior director of international soccer promotions for Reebok and Puma, John has directly signed some of the biggest names and clubs in world football (Liverpool, Argentina National team, Dennis Bergkamp, among others). His unparalleled experience continues to open up unique pathways for our clients to capitalize on endorsement opportunities and grow their personal brand. ',
        
    }

];

export default data2;
