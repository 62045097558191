import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import TeamTwo from "../blocks/team/TeamTwo";


class About extends Component{
    render(){
        let title = 'About',
        description = 'MWD Soccer Agency was founded by Mike Dietze, a former professional player with extensive business experience in sport management and marketing. Utilizing this experience along with a wealth of high-level connections he developed during his time as a pro, Mike established MWD Soccer to thoroughly and selflessly represent footballers all over the globe.';
        const description2 = 'With Mike at the helm as a FIFA-licensed agent, the MWD Soccer team focuses on all aspects of player representation. From negotiating contracts and securing endorsement opportunities, to investment guidance and post-career advisement, player\'s careers are in the safest hands possible.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/player.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                            <p className="description">{description2}</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}


                    {/* Start CounterUp Area */}
                    <div className="rn-counterup-area pb--120 bg_color--1" style={{marginTop: "5em"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

           

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--5">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2>Bio</h2>
                                        {/* <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p> */}
                                    </div>
                                </div>
                            </div>
                            <TeamTwo column="col-lg-4" teamStyle="" item="3" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

            

               

                

                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About