import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiBriefcase, FiTrendingUp, FiThumbsUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/FooterTwo";
import France from "../../public/assets/images/Flag_of_France.png";
import Germany from "../../public/assets/images/germany.png";
import Ghana from "../../public/assets/images/ghana.png";
import Portugal from "../../public/assets/images/portugal.png";
import PartnerLogo from "../../public/assets/images/partnerlogo/PartnerLogo.jpeg";
import Footprestige from "../../public/assets/images/partnerlogo/footprestige.jpg";
import Soccer from "../../public/assets/images/partnerlogo/soccer.png";
import NKB from "../../public/assets/images/partnerlogo/NKB.jpg";


const ServiceList = [
        {
        logo: <img src={PartnerLogo} style={{borderRadius: "100%", width: "2em"}}></img>,
        title: 'Sport Advisor Consulting',
        icon: <img src={France} style={{borderRadius: "5px", width: "1.5em", marginTop: "0.5em"}}></img>
        },
        {
        logo: <img src={Footprestige} style={{borderRadius: "100%", width: "2em"}}></img>,
        title: 'FootPrestige',
        icon: <img src={Portugal} style={{borderRadius: "5px", width: "1.5em", marginTop: "0.5em"}}></img>
        },
        {
        logo: <img src={Soccer} style={{borderRadius: "100%", width: "2em"}}></img>,
        title: 'LifeFoot Agency',
        icon: <img src={Germany} style={{borderRadius: "5px", width: "1.5em", marginTop: "0.5em"}}></img>
        },
        {
        logo: <img src={NKB} style={{borderRadius: "100%", width: "2em"}}></img>,
        title: 'NKB Sports Agency',
        icon: <img src={Ghana} style={{borderRadius: "5px", width: "1.5em", marginTop: "0.5em"}}></img>
        }
    
    
]
class Partners extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Partners' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Partners'}   />
                {/* End Breadcrump Area */}

                 {/* Start Service Area */}
                 <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.logo}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

            

                {/* Start Back To Top */}
                {/* <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div> */}
                {/* End Back To Top */}
                
                <Footer />


            </React.Fragment>
        )
    }
}
export default Partners;