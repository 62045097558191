import React from "react";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import DiarraImage from './Diarra.jpeg';
import Acloque from './acloque.jpg';

let data = [
    {
        images: 'https://img.a.transfermarkt.technology/portrait/big/569049-1678030532.jpg?lm=1',
        title: 'Gaoussou Samaké',
        designation: '#72',
        link: 'https://www.transfermarkt.us/gaoussou-samake/profil/spieler/569049'
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/big/865122-1677923981.jpg?lm=1',
        title: 'Nanan Houssou',
        designation: '#19',
        link: 'https://www.transfermarkt.us/nanan-houssou/profil/spieler/865122',
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/big/543560-1683082422.jpg?lm=1',
        title: 'Kairou Amoustapha',
        designation: '#29',
        link: 'https://www.transfermarkt.us/kairou-amoustapha/profil/spieler/543560',
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/big/579742-1639599650.jpg?lm=1',
        title: 'Kwadwo Amoako',
        designation: '#4',
        link: 'https://www.transfermarkt.us/kwadwo-amoako/profil/spieler/579742'
    },
    {
        images: DiarraImage,
        title: 'Zoumana Diarra',
        designation: '',
        link: 'https://www.transfermarkt.us/zoumana-diarra/profil/spieler/993309'
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/header/344458-1649268266.JPG?lm=1',
        title: 'Luka Malesevic',
        designation: '#6',
        link: 'https://www.transfermarkt.us/luka-malesevic/profil/spieler/344458'
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/header/578094-1683131855.png?lm=1',
        title: 'Fard Ibrahim',
        designation: '#23',
        link: 'https://www.transfermarkt.us/fard-ibrahim/profil/spieler/578094'
    },
    {
        images: 'https://img.a.transfermarkt.technology/portrait/header/718123-1665477743.jpeg?lm=1',
        title: 'Samuel Nongoh',
        designation: '',
        link: 'https://www.transfermarkt.us/samuel-nongoh/profil/spieler/718123'
    },
    {
        images: Acloque,
        title: 'Aaron Acloque',
        designation: '',
        link: ''
    }
    



];

export default data;