// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import HomePortfolio from './home/HomePortfolio';

// Element Layout
import Service from "./elements/Service";
import About from "./elements/About";
import Contact from "./elements/Contact";
import error404 from "./elements/error404";


// Blocks Layout

import Team from "./blocks/Team";
import Partners from "./blocks/Partners";
import Testimonial from "./blocks/Testimonial";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomePortfolio}/>

                        {/* Element Layout */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>

                        {/* Blocks Elements  */}
                        {/* <Route exact path={`${process.env.PUBLIC_URL}/clients`} component={Team}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/partners`} component={Partners}/>

                        {/* Error Page */}
               
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();